<template lang="pug">
    Form(@save="saveVnz" href='formCreate')
</template>

<script>
import { mapActions } from 'vuex'
import Form from './Form'

export default {
  name: 'create-vnz',
  components: {
    Form
  },
  methods: {
    ...mapActions(['setPriceForAdvTraining']),
    async saveVnz (body) {
      await this.setPriceForAdvTraining({ body })
    }
  }
}
</script>
